<template>
    <div>
    <transition name="transitionRouter">
        <router-view />
    </transition>
    </div>
</template>
<script>
export default {
}
</script>
<style scoped>
.transitionRouter-enter-active,
.transitionRouter-leave-active {
    transition: all .4s;
}
.transitionRouter-enter,
.transitionRouter-leave{
    opacity: 0;
}
</style>